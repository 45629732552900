<script>

import { changePwd } from "@/api/my";
export default {    
    name:'AccountSetting_LoginPassword',
    components: {
        Header: () => import('components/base/Header'),

        'mt-field':app.Mint.Field,
        'mt-button':app.Mint.Button,
        'mt-header':app.Mint.Header,
    },
    data() {
        return {
            old_password: "",
            new_password: "",
            new_password1: "",
            nickname: "",

            header: {
                mainTitle: getWord(['modify', 'password7']),
            },
        };
    },
    methods: {
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        getmessage() {
            changePwd(this.old_password, this.new_password, this.new_password1).then(
                result => {
                    if (result.data.code == "SUCCESS") {
                        app.Mint.Toast(getWord('modify_success2'));
                        this.old_password=this.new_password=this.new_password1='';
                    } else {
                        app.Mint.Toast(result.data.msg);
                    }
                }
            );
        }
    },
    mounted() {
        this.nickname = JSON.parse(localStorage.getItem("user")).nickname;
    }
};
</script>
<template>
    <section id="personal" :class="_TEMPLATE">        
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="myposition"></div>
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="main_middle">                
                <mt-field class="main_middle_field" :label="getWord('username6')" :placeholder="getWord(['fill', 'username4'])" type="text" v-model="nickname" disabled></mt-field>
                <mt-field class="main_middle_field" :label="getWord('password6')" :placeholder="getWord(['fill', 'password6'])" type="password" v-model="old_password"></mt-field>
                <mt-field class="main_middle_field" :label="getWord('password5')" :placeholder="getWord(['fill', 'password5'])" type="password" v-model="new_password"></mt-field>
                <mt-field class="main_middle_field" :label="getWord('check_password')" :placeholder="getWord('double_check_password2')" type="password" v-model="new_password1"></mt-field>
            </div>
            <mt-button class="mt_button" type="primary" @click="getmessage()">{{ getWord('modify_confirm') }}</mt-button>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="main_middle">                
                <mt-field class="main_middle_field" :label="getWord('username4')" :placeholder="getWord(['fill', 'username4'])" type="text" v-model="nickname" disabled></mt-field>
                <mt-field class="main_middle_field" :label="getWord('password6')" :placeholder="getWord(['fill', 'password6'])" type="password" v-model="old_password"></mt-field>
                <mt-field class="main_middle_field" :label="getWord('password5')" :placeholder="getWord('password_rules21',{
                    '$1':'8',
                    '$2':'12',
                })" type="password" v-model="new_password"></mt-field>
                <mt-field class="main_middle_field" :label="getWord('check_password')" :placeholder="getWord('double_check_password2')" type="password" v-model="new_password1"></mt-field>
            </div>
            <mt-button class="mt_button" type="primary" @click="getmessage()">{{ getWord('modify_confirm') }}</mt-button>
        </template>  
    </section>
</template>
<style lang="scss" scoped style="text/css">
#personal {

    &.template-3 {
        background-color: #EEEEEE;
        height: 100%;

        .main_middle {
            float: none;
            width: auto;
            box-shadow: none;
            margin: .25rem 0;
            padding:0 .25rem;
            background-color:#ffffff;            
        }

        .mint-cell {
            float: none;
            height: auto;
            border-bottom:0;
            width: auto;
            min-height: 0;
            padding: .25rem 0;
            border-top: 1px solid #B0B0B0;

            &:first-child {
                border-top:0;      
            }

            &:last-child {
                background-image: none;
            }

            /deep/ .mint-cell-wrapper {
                font-size: .3rem;
                background-image: none;
                margin: 0 .25rem;
                padding: 0;                

                .mint-cell-text {
                    color: #5F646E;
                }

                input {
                    color: #5F646E;

                    ::placeholder {
                        color: #B0B0B0;
                    }
                }
            }
        }

        .mt_button {
            margin: 0 auto;
            display: block;
            background-color:#EC2829;
        }      
    }

    .mint-cell {

        /deep/ input:disabled{
            background-color: transparent;
        }
    }

    .myposition {
        background-color: rgba(239, 239, 239, 1);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .main_middle {
        float: left;
        width: 100%;
        box-shadow: 0 0 0.05rem #ccc;
        margin-top: 0.4rem;
    }

    .main_middle_field {
        float: left;
        height: 0.93rem;
        border-bottom: 1px solid #ccc;
        width: 100%;
    }

    .mt_button {
        width: 90%;
        margin: 0 auto;
        margin-top: 0.3rem;
        margin-left: 5%;
    }    
}

</style>
